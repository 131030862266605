import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { change, untouch } from 'redux-form';
import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import moment from 'moment';

import { jobDetailsForm } from '../../constants/FormConstants';
import jobStandardIds from '../../constants/JobStandardIds';
import { isCompleted } from '../../util/JobStateHelper';
import { getPlasticWrapStatusTitle } from '../../util/PlasticWrapStatusHelper';
import {
  isImportJobType,
  isImportAndHeatTreatment,
  isExportJobType,
  isExportAndLogs,
  isExportOther,
  isImportAndContainer,
  isExportAndContainer,
  isBreakBulkJobType,
} from '../../util/JobTypeHelper';
import { isGasFree, isMethylBromide } from '../../util/TreatmentTypeHelper';
import { isValidDate } from '../../util/Validators';

import PlainTextField from '../fields/PlainTextField';
import PlainTextDateField from '../fields/PlainTextDateField';
import { Band, ToggleButton } from '../layout';
import { formValueSelector, getFormValues } from 'redux-form';
import { getCurrentJob } from './treatmentResultRecords/selectors';

const propTypes = {
  id: PropTypes.string,
  currentState: PropTypes.string,
  history: PropTypes.object.isRequired,
  privateClient: PropTypes.bool,
  stopCredit: PropTypes.bool,
  type: PropTypes.object,
  containerType: PropTypes.object,
  category: PropTypes.object,
  area: PropTypes.object,
  technician: PropTypes.object,
  location: PropTypes.string,
  vessel: PropTypes.string,
  voyage: PropTypes.string,
  standard: PropTypes.object,
  sizeOfContainer: PropTypes.object,
  treatmentType: PropTypes.object,
  invoiceNumber: PropTypes.string,
  treatmentTypeDetails: PropTypes.shape({
    destination: PropTypes.string,
    markings: PropTypes.array,
    numberOfContainers: PropTypes.any,
    commodity: PropTypes.string,
    disclaimerReceived: PropTypes.bool,
    freshProduce: PropTypes.bool,
    jobDescription: PropTypes.string,
    status: PropTypes.string,
  }),
  treatmentDetails: PropTypes.shape({
    treatmentTypeId: PropTypes.string,
    comments: PropTypes.any,
    dosageRate: PropTypes.any,
    mandatoryMinimumTemperature: PropTypes.any,
    treatmentPeriod: PropTypes.any,
    expectedQuantity: PropTypes.any,
    expectedVentilationDate: PropTypes.any,
    plasticWrapStatus: PropTypes.any,
    endPointReadingPercentage: PropTypes.any,
    dateIntendedTreatment: PropTypes.any,
  }),
  estimatedTimeOfArrival: PropTypes.string,
  dateOfRequest: PropTypes.string,
  importShipmentDetails: PropTypes.object,
  exportShipmentDetails: PropTypes.object,
  canEditJob: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  parentJobReference: PropTypes.any,
};

class JobDetailsSummary extends Component {
  state = {
    collapsed: isCompleted(this.props.currentState) ? false : true,
  };



  toggleSeeMore = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  editJob = () => {
    const { history, id } = this.props;
    history.push('/jobs/details/edit/' + id);
  };

  isDaffId = (standard, jobStandardIds) => {
    if (!standard) return false;
    return (
      standard.id === jobStandardIds.DaffId ||
      standard.id === jobStandardIds.DaffArhopalusId
    );
  };

  showClientTypeBorder = () => {
    const { privateClient, stopCredit } = this.props;
    return privateClient || stopCredit;
  };

  render() {
    let stopCredit;
    const {
      type,
      containerType,
      category,
      area,
      technician,
      location,
      vessel,
      voyage,
      standard,
      sizeOfContainer,
      treatmentType,
      invoiceNumber,
      privateClient,
      doNotCertify,

      treatmentTypeDetails: {
        destination,
        markings,
        numberOfContainers,
        commodity,
        disclaimerReceived,
        freshProduce,
        jobDescription,
        jas,
        covered,
        movement, 
      },
      treatmentDetails: {
        treatmentTypeId,
        comments,
        dosageRate,
        mandatoryMinimumTemperature,
        treatmentPeriod,
        expectedQuantity,
        expectedVentilationDate,
        plasticWrapStatus,
        endPointReadingPercentage,
        dateIntendedTreatment,
        doseToConcentration,
        gasAssessment,
        residueRelocation,
        residueRemoval,
        disposalResidue,
        quenchingResidue,
        chamberMonitoring,
        callOutFee,
        removalDisposalPipeFan,
        
      },
      estimatedTimeOfArrival,
      dateOfRequest,
      importShipmentDetails = {},
      exportShipmentDetails = {},
      canEditJob,
      parentJobReference,
      clientDetails,
    } = this.props;
    const {
      bookingReference,
      vbCode,
      cargoLocation,
      splitVessel,
      splitVesselPercentage,
      orderNumber,
      _BillOfLading,
      bacc,
      tpin
    } =  this.props.exportShipmentDetails!=undefined?exportShipmentDetails:this.props.importShipmentDetails !=undefined?importShipmentDetails:"";
    const { collapsed } = this.state;
    const dateOfIntendedTreatment =
      this.props.treatmentDetails.dateIntendedTreatment;
    const isContainer =
      isImportAndContainer(type) || isExportAndContainer(type);
    let status = 'Active';
    if (clientDetails != null && clientDetails !== undefined) {
      status = clientDetails.status;
      stopCredit = clientDetails.stopCredit;
    }

console.log('jdSummary',movement)

    return (
      <Band>
        <div className="form-section">
          <Container>
            <Row>
              <Col xs="8">
                <h4>Job Details</h4>
              </Col>
              <Col xs="4" className="text-right">
                {canEditJob && status === 'Active' ? (
                  <ToggleButton
                    color="light"
                    onClick={this.editJob}
                    ligature="mode_edit"
                  />
                ) : null}
              </Col>
              <Col xs="12">
                <Row>
                  <Col
                    xs="4"
                    className={privateClient ? 'clienttype-checked' : ''}
                  >
                    <PlainTextField
                      name="privateClient"
                      label="Private Client"
                      value={privateClient ? 'Yes' : 'No'}
                    />
                  </Col>
                  <Col
                    xs="4"
                    className={stopCredit ? 'clienttype-checked' : ''}
                  >
                    <PlainTextField
                      name="stopCredit"
                      label="Stop Credit"
                      value={stopCredit ? 'Yes' : 'No'}
                    />
                  </Col>
                  <Col
                    xs="4"
                    className={doNotCertify ? 'clienttype-checked' : ''}
                  >
                    <PlainTextField
                      name="doNotCertify"
                      label="Do Not Certify"
                      value={doNotCertify ? 'Yes' : 'No'}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="8">
                <PlainTextField
                  name="location"
                  label="Location"
                  value={location}
                />
              </Col>
              <Col xs="4">
                <PlainTextField
                  name="parentJobReference"
                  label="Parent Job #"
                  value={parentJobReference}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField name="vessel" label="Vessel" value={vessel} />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField name="voyage" label="Voyage" value={voyage} />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="destination"
                  label="Destination"
                  value={destination}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="treatmentType"
                  label="Treatment Type"
                  value={treatmentType && treatmentType.displayName}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="dosageRate"
                  label="Dosage Rate"
                  value={dosageRate}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="standard"
                  label="Standard"
                  value={standard && standard.displayName}
                />
              </Col>
              {this.isDaffId(standard, jobStandardIds) ? (
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="plasticWrapStatus"
                    label="Plastic Wrap Status"
                    value={getPlasticWrapStatusTitle(plasticWrapStatus)}
                  />
                </Col>
              ) : null}
              <Col xs="6" lg="4">
                <PlainTextField
                  name="mandatoryMinimumTemperature"
                  label="Mandatory Minimum Temperature"
                  value={mandatoryMinimumTemperature}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="treatmentPeriod"
                  label="Treatment Period"
                  value={treatmentPeriod}
                />
              </Col>
              {isBreakBulkJobType(type) ? (
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="jas"
                    label="Client Vol M3"
                    value={jas}
                  />
                </Col>
              ) : null}

              {isMethylBromide(treatmentTypeId) ? (
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="endPointReadingPercentage"
                    label="End Point Reading"
                    value={
                      endPointReadingPercentage
                        ? `${endPointReadingPercentage}%`
                        : null
                    }
                  />
                </Col>
              ) : null}
              <Col xs="8">
                <PlainTextField
                  name="Date of Intended Treatment"
                  label="Date of Intended Treatment"
                  value={moment(dateOfIntendedTreatment).format(
                    'DD/MM/YYYY HH:mm',
                  )}
                />
              </Col>
              <Col xs="4" className={covered ? 'clienttype-checked' : ''}>
                <PlainTextField
                  name="Covered"
                  label="covered"
                  value={covered ? 'Yes' : 'No'}
                />
              </Col>

              <Col xs="4" className={doseToConcentration ? 'clienttype-checked' : ''}>
                <PlainTextField
                  name="doseToConcentration"
                  label="Dose To Volume"
                  value={doseToConcentration ? 'Yes' : 'No'}
                />
              </Col>

              {isExportAndLogs(type) ? (
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="expectedQuantity"
                    label="Expected Quantity (mb/kg)"
                    value={expectedQuantity}
                  />
                </Col>
              ) : null}
              {isExportAndLogs(type) ? (
                <Col xs="6" lg="4">
                  <PlainTextDateField
                    name="expectedVentilationDate"
                    label="Expected Ventilation Date"
                    value={expectedVentilationDate}
                  />
                </Col>
              ) : null}

              <Col xs="12" lg="12">
                <PlainTextField
                  name="markings"
                  label="Markings"
                  value={
                    markings
                      ? markings
                          .map((marking) =>
                            isContainer
                              ? `${marking.containerNumber}${
                                  marking.sealNumber
                                    ? ` ${marking.sealNumber}`
                                    : ''
                                }`
                              : marking.markingNumber,
                          )
                          .join(', ')
                      : ''
                  }
                />
              </Col>
              <Col xs="12" lg="12">
                <PlainTextField
                  name="comments"
                  label="Comments"
                  value={comments}
                />
              </Col>
            </Row>
            {movement!=undefined?
            <Row>
      
            <Col
                    xs="4"
                    className={movement>=1 ? 'clienttype-checked' : ''}
                  >
                    <PlainTextField
                      name="movementIndicator"
                      label="Movement"
                      value={movement>=1 ? 'Yes' : 'No'}
                    />
                  </Col>
                  <Col xs="2" lg="2">
                <PlainTextField
                  name="Movement"
                  label="Movement Fee"
                  value={movement}
                />
              </Col>
                  </Row>
              :null}
            <Collapse isOpen={!collapsed} className="mt-3">
              <Row>
                <Col xs="6" lg="4">
                  <PlainTextDateField
                    name="estimatedTimeOfArrival"
                    label="ETA"
                    value={estimatedTimeOfArrival}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextDateField
                    name="dateOfRequest"
                    label="Date of Request"
                    value={dateOfRequest}
                  />
                </Col>
                {isImportJobType(type) ? (
                  <Col xs="6" lg="4">
                    <PlainTextField
                      name="billOfLading"
                      label="Bill of Lading"
                      value={_BillOfLading}
                    />
                  </Col>
                ) : null}
                {isImportJobType(type) ? (
                  <Col xs="6" lg="4">
                    <PlainTextField name="bacc" label="BACC #" value={bacc} />
                  </Col>
                ) : null}
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="orderNumber"
                    label="Order #"
                    value={orderNumber}
                  />
                </Col>
                {isImportAndHeatTreatment(type) ? (
                  <Col xs="6" lg="4">
                    <PlainTextField name="tpin" label="TPIN" value={tpin} />
                  </Col>
                ) : null}
                {isExportJobType(type) ? (
                  <Col xs="6" lg="4">
                    <PlainTextField
                      name="bookingReference"
                      label="Booking Reference"
                      value={bookingReference}
                    />
                  </Col>
                ) : null}
                {/*isExportAndLogs(type) ? (
                  <Col xs="6" lg="4">
                    <PlainTextField
                      name="vbCode"
                      label="VB Code/Link"
                      value={vbCode}
                    />
                  </Col>
                ) : null*/}
                {isExportAndLogs(type) ? (
                  <Col xs="6" lg="4">
                    <PlainTextField
                      name="cargoLocation"
                      label="Cargo Location"
                      value={cargoLocation}
                    />
                  </Col>
                ) : null}
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="containerType"
                    label="Container Type"
                    value={containerType && containerType.displayName}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="category"
                    label="Category"
                    value={category && category.displayName}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="sizeOfContainer"
                    label="Size of Container"
                    value={sizeOfContainer && sizeOfContainer.displayName}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="numberOfContainers"
                    label="Number of Containers"
                    value={numberOfContainers}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="area"
                    label="Area"
                    value={area && area.displayName}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="commodity"
                    label="Commodity"
                    value={commodity}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="disclaimerReceived"
                    label="Disclaimer Received"
                    value={disclaimerReceived ? 'Yes' : 'No'}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="freshProduce"
                    label="Fresh Produce"
                    value={freshProduce ? 'Yes' : 'No'}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="technician"
                    label="Technician"
                    value={technician && technician.name}
                  />
                </Col>
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="invoiceNumber"
                    label="Invoice #"
                    value={invoiceNumber}
                  />
                </Col>
                {/*isExportAndLogs(type) || isExportOther(type) ? (
                  <Col xs="6" lg="4">
                    <PlainTextField
                      name="splitVessel"
                      label="Split Vessel"
                      value={splitVessel ? 'Yes' : 'No'}
                    />
                  </Col>
                ) : null*/}
                {/*isExportAndLogs(type) || isExportOther(type) ? (
                  <Col xs="6" lg="4">
                    <PlainTextField
                      name="splitVesselPercentage"
                      label="Split Vessel Percentage"
                      value={splitVesselPercentage}
                    />
                  </Col>
                ) : null*/}
                <Col lg="12">
                  <PlainTextField
                    name="jobDescription"
                    label="Job Description"
                    value={jobDescription}
                  />
                </Col>
              </Row>
              {isGasFree(treatmentTypeId)?
                <Col>
                <PlainTextField
                name="treatmentDetails.gasAssessment"
                label="Gas Assessment"
                value={gasAssessment ? 'Yes' : 'No'}
              />
              <PlainTextField
                name="treatmentDetails.residueRelocation"
                label="Residue Relocation"
                value={residueRelocation ? 'Yes' : 'No'}
              />
                <PlainTextField
                name="treatmentDetails.residueRemoval"
                label="Residue Removal"
                value={residueRemoval ? 'Yes' : 'No'}
              />
                <PlainTextField
                name="treatmentDetails.disposalResidue"
                label="Disposal of Residue"
                value={disposalResidue ? 'Yes' : 'No'}
              />
                <PlainTextField
                name="treatmentDetails.quenchingResidue"
                label="Quenching Residue"
                value={quenchingResidue ? 'Yes' : 'No'}
              />
                <PlainTextField
                name="treatmentDetails.chamberMonitoring"
                label="Chamber Monitoring"
                value={chamberMonitoring ? 'Yes' : 'No'}
              />
                <PlainTextField
                name="treatmentDetails.callOutFee"
                label="Call Out Fee"
                value={callOutFee ? 'Yes' : 'No'}
              />
               <PlainTextField
                name="treatmentDetails.removalDisposalPipeFan"
                label="Removal & Disposal of Used Piping & or Fans"
                value={removalDisposalPipeFan ? 'Yes' : 'No'}
              />

                
            </Col>:null}
            <PlainTextField
                name="splitCost "
                label="Split Cost"
                value={this.props.splitCost?'Yes':'No'}
              />
               <PlainTextField
                name="travelZone "
                label="Travel Zone"
                value={this.props.travelZone}
              />
            </Collapse>
            <Button color="light" className="mt-3" onClick={this.toggleSeeMore}>
              {collapsed ? 'See More' : 'See Less'}
              {
                <i className="material-icons ml-1">
                  {collapsed ? 'expand_more' : 'expand_less'}
                </i>
              }
            </Button>
          </Container>
        </div>
      </Band>
    );
  }
}

JobDetailsSummary.propTypes = propTypes;

export default JobDetailsSummary;
